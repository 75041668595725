<template>
  <div class="page-error-401">
    page {{ title }} is working!
  </div>
</template>

<script lang="js">
import Error401Page from './error-401';

export default Error401Page;
</script>

<style lang="scss">
@import "./error-401";
</style>
